@font-face {
  font-family: "gilroy";
  src: url("../../font/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "gilroy-thin";
  src: url("../../font/Gilroy-Thin.ttf");
}

@font-face {
  font-family: "gilroy-medium";
  src: url("../../font/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "gilroy-black";
  src: url("../../font/Gilroy-Black.ttf");
}

@font-face {
  font-family: "gilroy-bold";
  src: url("../../font/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "digital-counter";
  src: url("../../font/Digital-Counter.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
}
