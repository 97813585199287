.adminPage {
  display: flex;
  background: linear-gradient(to right, #efefff 10%, #e6e6ef 50%);

  .admMenu {
    width: 15vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #66e, #33a 45%);
    box-shadow: 2px 0px 6px 0px rgba(30, 30, 195, 0.6);

    .menuLogo {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      h1 {
        color: white;
        font-size: 0.7rem;
        font-family: gilroy-thin;
        font-style: italic;
        position: absolute;
        bottom: 0.6rem;
        left: 5.7rem;
      }
      img {
        width: 15rem;
        margin-top: -10px;
        margin-left: -15px;
      }
    }
    .menuItems {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem;

      .active {
        p {
          color: white;
        }
        .iconMenu {
          color: white;
        }
      }

      div {
        padding: 1.5rem 1rem;
        font-size: 1.3rem;
        color: #aaf;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
          padding-left: 0.7rem;
          transition: color ease 0.3s;
        }
        .iconMenu {
          color: #aaf;
          transition: color ease 0.3s;
        }

        &:hover {
          p {
            color: rgba(158, 158, 228, 0.6);
          }
          .iconMenu {
            color: rgba(158, 158, 228, 0.6);
          }
        }
      }
    }
  }
  .pageContent {
    width: 85vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .pageContentStructure {
    width: 80vw;
    height: 98vh;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    margin: 1rem;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 0px rgba(61, 61, 82, 0.2);

    .pageTopBar {
      width: 100%;
      background: #e6e6ff;
      max-height: 6rem;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      padding: 1rem;
    }
    .tableContent {
      background: white;
      width: 100%;
      height: 100%;
      // padding: 1rem;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      overflow-y: auto;
      overflow-x: hidden;

      table {
        width: 100%;

        tr {
          margin: 1rem;
          border-bottom: 1px solid #eee;

          img {
            object-fit: contain;
          }
          td {
            padding: 1rem;
            height: 7rem;
            text-align: center;
            vertical-align: middle;

            button {
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }
    }
  }
}
