@mixin m-cards($top-position: 0,
  $left-position: 0,
  $img-margin: 0,
  $font-s: 2.05rem,
  $align-txt: "") {
  position: absolute;
  top: $top-position;
  left: $left-position;
  padding: 1rem;
  width: 22.8rem;
  height: 34rem;
  border-radius: 12rem;
  text-align: center;
  overflow: hidden;
  // opacity: 0;

  @include extra-large-device {
    transform: scale(.77);
  }

  @include mobile {
    transform: scale(.63);
  }

  @include small-mobile {
    transform: scale(.45);
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 10;
    background-image: url(../../assets/img/fundoLateral.png);
    width: 7rem;
    height: 7rem;
    object-fit: contain;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    background-image: url(../../assets/img/fundoLateral.png);
    width: 7rem;
    height: 7rem;
    object-fit: contain;
  }

  img {
    margin: $img-margin;
  }

  h1 {
    font-size: 3.5rem;
    font-family: gilroy-medium;
    text-align: $align-txt;
    padding: 0 2rem;

    @include mobile {
      font-size: 3.3rem;
      padding: 0 1.5rem;
    }
  }

  ul {
    margin: 1.3rem 0;


    li {
      text-transform: capitalize;
      font-size: $font-s;
      margin: 0.7rem 0;
      padding: 0 2rem;
      text-align: $align-txt;

      @include mobile {
        padding: 0 1.5rem;
        font-size: 1.9rem;
        margin: 0.6rem 0;
      }

      @include small-mobile {
        padding: 0 1.5rem;
        font-size: 2.1rem;
      }
    }
  }
}

.m-card-left {
  @include m-cards($top-position: 18rem,
    $left-position: 12rem,
    $img-margin: 3rem 0 2.8rem 0, $align-txt: left);
  border-bottom-left-radius: 0;
  background: linear-gradient(45deg, $purple 25%, $light-purple 80%);
  // animation: fadeUp 1s ease-in-out .2s forwards;

  @include extra-large-device {
    top: 20%;
    left: 15%;
  }

  @include mobile {
    left: -12%;
    top: 31%;
  }

  @include small-mobile {
    left: -25%;
    top: 19%;
  }
}

.m-card-right {
  @include m-cards($top-position: 11.5rem,
    $left-position: 36rem,
    $img-margin: 3rem 0 0 0, $align-txt: right);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 12rem 12.5rem;
  background: linear-gradient(30deg, $light-purple 20%, $dark-blue 80%);
  // animation: fadeDown 1s ease-in-out .2s forwards;

  @include extra-large-device {
    top: 0;
    left: 59%;
  }

  @include mobile {
    left: 23.5%;
    top: -8%;
  }

  @include small-mobile {
    left: 10%;
    top: -20%;
  }
}