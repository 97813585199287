.page-project {
  width: 100vw;
  height: 100vh;
  color: $p-color;
  display: grid;
  place-items: center;

  &-content {
    width: 75%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include extra-large-device {
      width: 83%;
      height: 74%;
    }

    @include mobile {
      width: 95%;
      height: 85%;
    }

    &-title {
      width: 73%;
      display: flex;
      justify-content: space-between;

      @include mobile {
        width: 100%;
        flex-direction: column;
      }

      &-item {
        display: flex;
        align-items: center;

        @include mobile {
          margin: .2rem 0;
        }

        .hue-dot {
          width: 30px;
          height: 30px;
          margin-left: 5px;
          border-radius: 100px;

          @include mobile {
            width: 15px;
            height: 15px;
          }
        }

        p {
          font-size: 1.4rem;
          font-family: gilroy-medium;

          @include extra-large-device {
            font-size: 1.1rem;
          }

          @include mobile {
            font-size: .9rem;
          }
        }
      }
    }

    .item-1 {
      @include mobile {
        margin-left: 15%;
      }
    }

    .item-2 {
      @include mobile {
        margin-left: 45%;
      }
    }

    .item-3 {
      @include mobile {
        margin-left: 75%;
      }
    }

    .projects {
      width: 100%;
      height: 25%;
      display: flex;

      @include mobile {
        height: 20%;
      }

      &-imageBox {
        width: 25%;
        height: 100%;
        display: grid;
        place-items: center;

        @include mobile {
          width: 20%;
        }

        img {
          object-fit: contain;
          max-width: 55%;

          @include mobile {
            max-width: 100%;
          }
        }
      }

      &-progressBar {
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .progress-bar {
          width: 90%;
          height: 3rem;
          padding: 8px 1rem;
          border-radius: 15px;

          @include extra-large-device {
            height: 2.7rem;
          }

          @include mobile {
            height: 2rem;
          }

          .progress-bar-vector {
            background: linear-gradient(to right, #01A4FF 1.98%, #390A9B 100%);
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
}