.page-home {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url(../../assets/img/bghome.png);
  background-size: cover;
  background-repeat: no-repeat;

  @include mobile {
    display: flex;
    justify-content: center;
  }

  &__content-text {
    position: absolute;
    top: 28.5%;
    left: 14.5%;
    text-align: left;

    @include mobile {
      top: 28%;
    }

    @include extra-large-device {
      left: 10.5%;
    }

    span {
      text-transform: uppercase;
      font-size: 2.5rem;
      font-style: italic;
      font-family: gilroy-thin;
      letter-spacing: 2px;
      line-height: 2.9rem;
      position: relative;

      @include mobile {
        font-size: 1.85rem;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -0.5rem;
        height: 0.5rem;
        width: 24.25rem;
        background: $blue;

        @include mobile {
          width: 80vw;
        }
      }
    }

    &__bolding-title {
      margin: 1.4rem 0;
      position: relative;

      @include mobile {
        margin: 16% 0;
      }

      @mixin mixin-box($main-delay: 1.1s, $other-delay: 0.6s, $box-width: "") {
        position: relative;
        overflow: hidden;
        display: inline-flex;

        b {
          display: flex;
          justify-content: center;
          width: $box-width;
          font-size: 3.75rem;
          font-family: gilroy-medium;
          color: $text-color;
          background: $blue;
          animation: fadeInText 0s $main-delay both;

          @include mobile {
            font-size: 3rem;
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: $blue;
          transform: translateX(-100%);
          animation: enlargeBg 0.5s $other-delay both,
            revealBg 0.5s $main-delay both;
        }
      }

      .title-box-1 {
        @include mixin-box($box-width: 14rem);

        @include mobile {
          @include mixin-box($box-width: 11rem);
        }
      }

      .title-box-2 {
        @include mixin-box($main-delay: 1.2s,
          $other-delay: 0.7s,
          $box-width: 9.8rem);

        @include mobile {
          @include mixin-box($box-width: 8rem, $main-delay: 1.2s,
            $other-delay: 0.7s);
        }
      }

      .title-box-3 {
        @include mixin-box($main-delay: 1.3s,
          $other-delay: 0.8s,
          $box-width: 16rem);

        @include mobile {
          @include mixin-box($box-width: 13rem, $main-delay: 1.3s,
            $other-delay: 0.8s);
        }
      }
    }

    p {
      font-style: italic;
      font-family: gilroy-thin;
    }

    p:last-child {
      text-transform: uppercase;
      font-size: 1.25rem;
      font-weight: bold;
      font-style: normal;
      font-family: gilroy;
      margin: 0.7rem 0;

      @include mobile {
        font-size: 1.2rem;
      }
    }
  }

  .h-waterMark {
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 5%;
    left: 0;

    @include mobile {
      display: none;
    }

    @include extra-large-device {
      top: 3%;
    }
  }

  .h-logo {
    width: 53.75vw;
    position: absolute;
    top: 31.1%;
    right: 5.5%;

    @include mobile {
      top: 6%;
      width: 90vw;
      right: 50%;
      transform: translateX(50%);
    }
  }
}