.page-mission {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;

  &-left {
    width: 50%;
    height: 100%;
    position: relative;

    @include mobile {
      width: 100%;
    }
  }

  &-right {
    height: 100%;
    width: 50%;
    position: relative;

    @include mobile {
      display: none;
    }

    .m-rocket {
      position: absolute;
      top: -2rem;
      // opacity: 0;
      z-index: 10;
      height: 650px;
      object-fit: contain;
      // transform: rotate(-50deg);

      @include extra-large-device {
        height: 550px;
      }

      @include mobile {
        display: none;
      }
    }
  }

  &-waterMark-text {
    position: absolute;
    top: 0;
    right: 3%;
    z-index: -1;
    font-size: 17.5rem;

    @include mobile {
      font-size: 13.5rem !important;
    }

    @include extra-large-device {
      font-size: 15.5rem;
      top: 2%;
      right: 0;
    }

    p {
      color: $p-color;
      text-transform: uppercase;
      font-family: gilroy-medium;
      font-weight: bold;
      opacity: 4%;
    }
  }
}