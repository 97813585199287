.graphicMarkers {
  position: absolute;
  color: black;
  font-family: gilroy-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;

  .topBox {
    border-radius: 10px;
    background: linear-gradient(267.77deg, #0282fd -2.46%, #474fc2 75.07%);
    width: 150px;
    height: 55px;
    position: absolute;
    top: -2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.6rem;
    color: white;

    padding: 5px;
    padding-top: 10px;
  }

  &.item1 {
    top: 18%;
    left: 58%;
  }
  &.item2 {
    top: 20.6%;
    left: 60.2%;
  }
  &.item3 {
    top: 22.5%;
    left: 45.7%;
  }
  &.item4 {
    top: 32%;
    left: 75.2%;
  }
  &.item5 {
    top: 40%;
    left: 22.5%;
  }
  &.item6 {
    top: 70%;
    left: 84%;
  }
  &.item7 {
    top: 86%;
    left: 10%;
  }
}
