.page-distribuition {
    width: 100vw;
    height: 100vh;
    display: flex;

    &-left {
        height: 100%;
        width: 50%;

        @include mobile {
            display: none;
        }

        &-box {
            position: relative;

            @include extra-large-device {
                top: -17%;
            }


            img {
                position: absolute;
                top: -9rem;
                right: 0;
                width: 100%;
                object-fit: contain;

                @include extra-large-device {
                    top: 0;
                }
            }

            &-colorMark {
                position: absolute;
                top: 20rem;
                right: 20%;
                width: 17rem;
                height: 17rem;
                background: linear-gradient(130deg, #fff 0%, #D6D5D5 100%);
                border: 1rem solid #D6D5D5;
                border-radius: 50%;
                opacity: 0;
                animation: flipInY 1s .7s, fadeInText 0s .7s forwards;

                @include extra-large-device {
                    width: 13.5rem;
                    height: 13.5rem;
                    border-width: .8rem;
                    top: 19.5rem;
                }

                img {
                    position: absolute;
                    top: -1.6rem;
                    left: .4rem;
                    object-fit: contain;
                    width: 95%;
                }
            }
        }
    }


    &-right {
        height: 100%;
        width: 50%;
        color: $p-color;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mobile {
            width: 100%;
            height: 100vh;
            align-items: center;
            justify-content: flex-start;
            padding-top: 15%;
        }

        h1 {
            font-size: 2.5rem;
            font-family: gilroy-black;
            // animation-delay: .2s;

            @include extra-large-device {
                font-size: 2.1rem;
            }

            @include mobile {
                font-size: 1.8rem;
            }
        }

        &-content-text {
            width: 80%;
            animation-delay: .4s;

            @include extra-large-device {
                width: 85%;
            }

            p {
                font-size: 2rem;
                // font-weight: bold;
                user-select: text;
                text-align: justify;
                margin: 3.5rem 0;

                @include extra-large-device {
                    font-size: 1.4rem;
                    margin: 7% 0;
                }

                @include mobile {
                    margin: 6% 0;
                    font-size: 1.2rem;
                }
            }

            .dist-link {
                text-decoration: none;
                font-size: 1.29rem;
                font-weight: bold;
                cursor: pointer;
                word-break: break-all;
                display: flex;
                justify-content: center;

                button {
                    padding: .8rem 1rem;
                    color: $blue;
                    font-size: 1.3rem;
                    border: 1px solid currentColor;
                    border-radius: .5rem;
                    text-transform: uppercase;
                    white-space: nowrap;
                    font-family: gilroy-medium;
                    font-weight: bold;
                    cursor: pointer;
                    transition: 200ms ease-in-out;

                    &:hover {
                        background-color: $blue;
                        color: #fff;

                    }

                    @include extra-large-device {
                        font-size: 1.2rem;
                    }

                    @include mobile {
                        font-size: 1rem;
                        transform: scale(.8);
                    }
                }
            }

            .valuesBox {
                width: 100%;
                animation-delay: 1.4s;

                span {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @include mobile {
                        flex-direction: column;
                        // align-items: flex-start;
                        margin: 5% 0;
                    }

                    p {
                        margin: 0;
                        color: $dark-blue;
                        font-weight: bold;
                        font-size: 1.8rem;
                        text-align: center;

                        @include extra-large-device {
                            font-size: 1.4rem;
                        }

                        @include mobile {
                            font-size: clamp(1.2rem, 1.5vh, 1.8rem);
                        }
                    }

                    b {
                        font-size: 1.6rem;
                        color: $dark-blue;
                        font-family: gilroy-black;

                        @include extra-large-device {
                            font-size: 1.2rem;
                        }

                        @include mobile {
                            font-size: clamp(1rem, 2vh, 1.8rem);
                        }
                    }
                }
            }
        }
    }
}

.path {
    stroke-dasharray: 40;
}