@mixin gradient-strokes($s-top: 0,
  $s-left: 0,
  $s-Zindex: 0,
  $s-width: 12rem,
  $s-height: 2.35rem) {
  position: absolute;
  top: $s-top;
  left: $s-left;
  z-index: $s-Zindex;
  width: $s-width;
  height: $s-height;
  background: linear-gradient(90deg, #02f0ff 0%, $purple 95%);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.35);
  border-radius: 100px;
  transform: rotate(52deg);
}

// filter: grayscale(50%) contrast(140%);

.gradient-stroke-one {
  @include gradient-strokes($s-top: 101px,
    $s-left: 135px,
    $s-width: 7rem,
    $s-height: 1rem);

  @include mobile {
    left: 1rem;
    top: 4rem;
  }
}

.gradient-stroke-two {
  @include gradient-strokes($s-top: 120px,
    $s-left: 38%,
    $s-width: 9rem,
    $s-height: 0.7rem);

  @include extra-large-device {
    top: 15%;
    left: 28%;
  }

  @include mobile {
    display: none;
  }
}

.gradient-stroke-three {
  @include gradient-strokes($s-top: 62%, $s-left: 56%, $s-width: 7rem, $s-height: .9rem);

  @include extra-large-device {
    top: 72%;
  }

  @include mobile {
    left: 0;
    top: 80%;
    z-index: -1;
  }
}

.gradient-stroke-four {
  @include gradient-strokes($s-top: 85%,
    $s-left: 22%,
    $s-width: 8rem,
    $s-height: 0.6rem);

  @include mobile {
    display: none;
  }
}

.gradient-stroke-five {
  @include gradient-strokes($s-top: 92%,
    $s-left: 48%,
    $s-width: 5rem,
    $s-height: 0.6rem);

  @include mobile {
    left: 75%;
    top: 84%;
  }
}

.gradient-stroke-six {
  @include gradient-strokes($s-top: 68%,
    $s-left: 80%,
    $s-width: 10rem,
    $s-height: 1.6rem,
    $s-Zindex: 10);
  filter: grayscale(35%) contrast(140%);

  @include mobile {
    left: 60%;
    top: 50%;
  }
}

.gradient-stroke-seven {
  @include gradient-strokes($s-top: 75%,
    $s-left: 82%,
    $s-width: 9rem,
    $s-height: 1.6rem);

  @include mobile {
    display: none;
  }
}

.gradient-stroke-eight {
  @include gradient-strokes($s-top: 37%,
    $s-left: 85%,
    $s-width: 8.9rem,
    $s-height: 1.6rem);
  filter: contrast(70%) brightness(120%);

  @include mobile {
    left: -17%;
    top: 40%;
  }
}

.gradient-stroke-nine {
  @include gradient-strokes($s-top: 32%,
    $s-left: 85%,
    $s-width: 7rem,
    $s-height: 0.4rem);

  @include mobile {
    // left: 1rem;
    top: 27%;
  }
}

// Associate Page

.gradient-stroke-1p8 {
  @include gradient-strokes($s-top: 200px,
    $s-left: 200px,
    $s-width: 7rem,
    $s-height: 0.6rem);

  @include mobile {
    left: 57%;
    z-index: -1;
  }
}

.gradient-stroke-2p8 {
  @include gradient-strokes($s-top: 30%,
    $s-left: 52%,
    $s-width: 5rem,
    $s-height: 0.4rem);

  @include mobile {
    top: 39%;
    left: 5%;
  }
}

.gradient-stroke-3p8 {
  @include gradient-strokes($s-top: 70%,
    $s-left: 56%,
    $s-width: 3rem,
    $s-height: 0.5rem);

  @include extra-large-device {
    top: 73.5%;
    left: 52%;
  }

  @include mobile {
    top: 80%;
    left: 71%;
  }
}

.gradient-stroke-4p8 {
  @include gradient-strokes($s-top: 85%,
    $s-left: 22%,
    $s-width: 8rem,
    $s-height: 0.6rem);

  @include mobile {
    top: 78%;
    left: -15%;
  }
}

.gradient-stroke-5p8 {
  @include gradient-strokes($s-top: 96%,
    $s-left: 48%,
    $s-width: 5rem,
    $s-height: 0.6rem);

  @include mobile {
    top: 85%;
    left: 35%;
  }
}

.gradient-stroke-6p8 {
  @include gradient-strokes($s-top: 70%,
    $s-left: 54.5%,
    $s-width: 4rem,
    $s-height: 0.6rem,
    $s-Zindex: 10);
  filter: grayscale(35%) contrast(140%);

  @include extra-large-device {
    top: 73%;
    left: 50%;
  }

  @include mobile {
    top: 80%;
    left: 74%;
  }
}

.gradient-stroke-7p8 {
  @include gradient-strokes($s-top: 94%,
    $s-left: 94%,
    $s-width: 6rem,
    $s-height: 0.6rem);

  @include mobile {
    top: 43%;
    left: 62%;
  }
}

.gradient-stroke-8p8 {
  @include gradient-strokes($s-top: 35%,
    $s-left: 95%,
    $s-width: 7rem,
    $s-height: 0.7rem);
  filter: contrast(70%) brightness(120%);

  @include mobile {
    display: none;
  }
}