@mixin transition($time, $property: all, $easing: ease-in) {
  transition: $property $time $easing;
}

$parallax-offset: 30vh;
$content-offset: 20vh;
$transition-speed: 1.2s;
$slide-number: 9;

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  // overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  height: 100vh + $parallax-offset;
  position: fixed;
  width: 100vw;
  transform: translateY($parallax-offset);
  @include transition($transition-speed, all, cubic-bezier(0.22, 0.44, 0, 1));

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, 0.3);
  }

  &:first-child {
    transform: translateY(-$parallax-offset / 2);

    .content-wrapper {
      transform: translateY($parallax-offset / 2);
    }
  }

  &:nth-child(2) {
    background: linear-gradient(252.58deg, #ffffff 4.96%, #f2f7ff 99.13%);
  }

  &:nth-child(3) {
    background: linear-gradient(252.58deg, #ffffff 4.96%, #f2f7ff 99.13%);
  }

  &:nth-child(4) {
    background: linear-gradient(252.58deg, #ffffff 4.96%, #f2f7ff 99.13%);
  }

  &:nth-child(5) {
    background: linear-gradient(252.58deg, #ffffff 4.96%, #f2f7ff 99.13%);
  }

  &:nth-child(6) {
    background: linear-gradient(252.58deg, #ffffff 4.96%, #f2f7ff 99.13%);
  }
}

@for $i from 1 to ($slide-number + 1) {
  .background:nth-child(#{$i}) {
    z-index: ($slide-number + 1) - $i;
  }
}

.content {
  &-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    // text-align: center;
    flex-flow: column nowrap;
    color: #fff;
    font-family: Montserrat;
    // text-transform: uppercase;
    transform: translateY($content-offset);
    will-change: transform;
    backface-visibility: hidden;
    @include transition(
      $transition-speed + 0.5,
      all,
      cubic-bezier(0.22, 0.44, 0, 1)
    );
  }
}

.background.up-scroll {
  transform: translate3d(0, -$parallax-offset / 2, 0);

  .content-wrapper {
    transform: translateY($parallax-offset / 2);
  }

  + .background {
    transform: translate3d(0, $parallax-offset, 0);

    .content-wrapper {
      transform: translateY($parallax-offset);
    }
  }
}

.background.down-scroll {
  transform: translate3d(0, -(100vh + $parallax-offset), 0);

  .content-wrapper {
    transform: translateY($content-offset);
  }

  + .background:not(.down-scroll) {
    transform: translate3d(0, -$parallax-offset / 2, 0);

    .content-wrapper {
      transform: translateY($parallax-offset / 2);
    }
  }
}
