@import 'm-picture-card';
@import 'm-mission-cards';
@import 'm-gradient-strokes';
@import 'm-glow-balls';

select {
    width: 3rem;
    color: #111;

    option {
        color: #111;
    }
}