@mixin reset-css {
  margin: 0;
  padding: 0;
  z-index: 0;
  box-sizing: border-box;
  vertical-align: baseline;
  border-collapse: collapse;
  user-select: none;
  list-style: none;
  text-decoration: none;
  font-family: gilroy;
  letter-spacing: 1px;

  &::selection {
    background-color: $purple;
    color: #fff;
    -webkit-text-fill-color: #fff;
  }
}
