.page-graph-responsive {
    @include extra-large-device {
        transform: scaleY(.7) scaleX(.75);
    }

    @include mobile {
        transform: scaleY(.77) scaleX(.5);
        align-items: flex-start;
    }

    &-container {
        @include mobile {
            width: 300px !important;
        }
    }

    h1 {
        font-family: gilroy-black !important;
        font-size: 1.6rem !important;
    }

    p {
        font-family: gilroy-medium !important;
        font-weight: normal !important;
        font-size: 1.2rem;
    }
}