.page-team {
    width: 100vw;
    height: 100vh;
    color: $p-color;

    .teamBox {
        position: absolute;
        width: 320px;
        height: 200px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;

        .linhaReta {
            position: absolute;
            left: 18%;
            top: -250%;
        }

        .equipImg {
            width: 120px;
            height: 120px;
            background: linear-gradient(159.49deg, #872ad8 15.4%, #20aaf6 88.05%);
            border-radius: 50%;
            object-fit: cover;
        }

        .equipImg2 {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin-left: -5px;
            background: linear-gradient(159.49deg, #872ad8 15.4%, #20aaf6 88.05%);
            object-fit: cover;
        }

        .TeamMateDesc {
            text-align: center;
            transform: scale(0.6);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: -12.6rem;

            h1 {
                font-family: gilroy-bold;
            }

            p {
                font-size: 1.3rem;
                max-width: 320px;
            }
        }
    }

    &-waterMark-text {
        position: absolute;
        top: 1%;
        left: 3%;
        z-index: -1;
        height: 100vh;
        width: 100vw;

        h1 {
            color: $p-color;
            text-transform: uppercase;
            font-family: gilroy-medium;
            font-weight: bold;
            opacity: 4%;
            text-align: justify;
            line-height: 1;
            white-space: nowrap;
            font-size: 12rem;

            @include extra-large-device {
                font-size: 8.5rem;
            }
        }

        i {
            position: absolute;
            bottom: 3%;
            left: -1%;
            font-size: 2rem;
            font-family: gilroy-thin;
            font-weight: bold;

            @include extra-large-device {
                font-size: 1.5rem;
            }

            @include mobile {
                bottom: 5%;
            }
        }
    }
}