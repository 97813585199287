.page-participate {
    width: 100vw;
    height: 110vh;
    background: linear-gradient(138.62deg, #022355 0.89%, #010D1E 95.72%);
    padding-top: 15vh;
    margin-top: -11vh;
    display: flex;
    align-items: center;

    &-content-text {
        position: absolute;
        z-index: 10;
        left: 18%;
        font-size: 2.6rem;
        font-weight: bold;
        white-space: nowrap;

        @include extra-large-device {
            transform: scale(.9);
            left: 7%;
        }

        @include mobile {
            font-size: 1.5rem;
            top: 20%;
            left: 50%;
            transform: translateX(-50%);
        }

        @mixin box-title($main-delay: 1.1s, $other-delay: 0.6s) {
            position: relative;
            overflow: hidden;
            display: inline-flex;

            p {
                font-family: gilroy-medium;
                padding: .1rem 1rem;
                width: 100%;
                animation: fadeInText 0s $main-delay both;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(92.03deg, #113485 34.12%, #104FE2 97.24%);
                transform: translateX(-100%);
                animation: enlargeBg 0.5s $other-delay both,
                    revealBg 0.5s $main-delay both;
            }
        }

        .box-title-1 {
            @include box-title;
        }

        .box-title-2 {
            @include box-title($main-delay: 1.2s,
                $other-delay: 0.7s);
        }

        .box-title-3 {
            @include box-title($main-delay: 1.3s,
                $other-delay: 0.8s);
        }

        .box-title-4 {
            @include box-title($main-delay: 1.4s,
                $other-delay: 0.9s);
        }
    }

    &-whatsapp-box {
        position: absolute;
        right: 10%;
        width: 41rem;
        height: 18.75rem;
        padding: 1rem 3rem;
        border-radius: 2.5rem;
        background: linear-gradient(97.77deg, rgba(75, 0, 124, 0.28) 6.62%, rgba(75, 0, 124, 0) 94.25%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2rem;

        @include extra-large-device {
            transform: scale(.9);
            right: 2%;
        }

        @include mobile {
            top: 45%;
            right: 50%;
            transform: translateX(50%);
            width: 85%;
            font-size: 1.5rem;
            white-space: nowrap;
            padding: 1rem 2rem;
        }

        p {
            font-family: gilroy-thin;
            font-weight: bold;
        }

        p:nth-child(1) {
            margin-bottom: 2rem;
            white-space: normal;
        }
    }
}