// ---------------------------------- //
// -- colors -- //
// ---------------------------------- //

$purple: #4b007c;
$light-purple: #8405CA;
$dark-blue: #0b3986;
$blue: #335cbe;
$text-color: #391180;
$p-color: #3D3D3D;

// ---------------------------------- //

$rocket-bezier: cubic-bezier(.17, .67, .83, .67);