@keyframes fadeInText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes revealBg {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes enlargeBg {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes rocketAnimation {
  0% {
    opacity: 0;
    transform: scale(.5) rotate(60deg) translateY(-10%) translateX(55%);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(0) translateY(0) translateX(0);
  }
}

@keyframes fadeDown {
  from {
    transform: translateY(40px);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeUp {
  from {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}