%glow-ball-bg {
    background: radial-gradient(circle at 38% 30%,
            #8b79de 0%,
            $purple 35%,
            #240b5e 65%,
            $purple 100%);
    border-radius: 50%;
}

.glow-ball-large {
    position: absolute;
    right: -50%;
    top: 14%;
    height: 50rem;
    width: 50rem;
    @extend %glow-ball-bg;

    @include mobile {
        display: none;
    }

    @include extra-large-device {
        height: 35rem;
        width: 35rem;
        right: -22rem;
    }
}

.glow-ball-2 {
    position: absolute;
    right: 56%;
    top: 60%;
    transform: rotate(90deg);
    height: 60px;
    width: 60px;
    @extend %glow-ball-bg;

    @include extra-large-device {
        top: 75%;
    }
}

.glow-ball-3 {
    position: absolute;
    right: 55%;
    top: 59%;
    transform: rotate(90deg);
    height: 12px;
    width: 12px;
    @extend %glow-ball-bg;

    @include extra-large-device {
        top: 74%;
    }
}