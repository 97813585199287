.card {
    perspective: 1000px;
    position: absolute;
    opacity: 1;
    z-index: 1000;
    background-color: transparent;

    &:hover {
        .inner {
            transform: rotateY(180deg);
        }

        // .popup {
        //   display: flex;
        //   position: absolute;
        //   top: 0;
        //   // margin-top: -30px;
        //   width: 100%;
        //   justify-content: center;
        // }
    }

    // .popup {
    //   display: none;
    // }

    .inner {
        background-color: transparent;
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        display: flex;
        flex-direction: column;
        border-radius: 50%;
        align-items: center;
        transform: rotateY(0deg);
        justify-content: center;

        @include extra-large-device {
            transform: rotateY(0deg) scale(.69);
        }

        .front,
        .back {
            background: linear-gradient(302.57deg, #4b007c 5.91%, #113485 99.64%);
            border-radius: 50%;
            width: 100%;
            height: 100%;
            position: absolute;
            align-items: center;
            justify-content: center;
            text-align: center;
            display: flex;

            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .back {
            transform: rotateY(180deg);
        }
    }
}