// media queries

// extra small devices / small-phones //
@mixin small-mobile {
    @media only screen and (max-width: 370px) {
        @content;
    }
}

// small devices / phones //
@mixin mobile {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

// portrait tablets and large phones //
@mixin tablets {
    @media only screen and (max-width: 770px) {
        @content;
    }
}

// vertical tablets and up //
@mixin small-device {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

// small laptops //
@mixin medium-device {
    @media only screen and (max-width: 992px) {
        @content;
    }
}

// laptops / small desktops //
@mixin large-device {
    @media only screen and (max-width: 1300px) {
        @content;
    }
}

// large laptops / medium desktops //
@mixin extra-large-device {
    @media only screen and (max-width: 1600px) {
        @content;
    }
}

// --------------------------------------------------------- //