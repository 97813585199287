@import "fonts";
@import "reset";
@import "variables";
@import "responsively";

* {
  @include reset-css;
}

body {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}
